<template>
  <div class="mine-page">
    <div class="info-edit">
      <p class="info-edit-title">给自己取一个好听的昵称吧～</p>
      <div class="info-edit-item">
        <van-field v-model="nickname" label="" placeholder="请输入昵称" maxlength="20" @input="changeValue()" />
      </div>
      <p class="edit-tips">昵称限制4～8个字，支持中英文和数字</p>
      <van-button class="btn-sure" block type="primary" @click="updateUser()">完成</van-button>
    </div>
  </div>
</template>

<script>
// import cookies from "@/libs/util.cookies";
import {Dialog} from "vant";

export default {
  data(){
    return {
      nickname:'',
      showLoading: false,
      formData: {
        'name': ''
      },
    }
  },
  created() {
  },
  mounted() {
  },
  methods:{
    updateUser() {
      let that = this
      if (that.showLoading == true) {
        return false
      }
      if (!that.nickname) {
        Dialog({message: "请输入昵称"})
        return
      }
      that.formData.name = that.nickname
      that.showLoading = true
      this.$api.UPDATE_USER_POST(that.formData).then(res => {
        if (!res.code) {
          const userinfo = JSON.parse(localStorage.getItem("userinfo"));
          userinfo.username = that.nickname
          localStorage.setItem("userinfo", JSON.stringify(userinfo));
        }
        Dialog({ message: res.msg });
        that.nickname = ''
        that.showLoading = false
      })
    },
    //文本验证
    changeValue() {
      let that = this
      let value = that.nickname; //校验的字段
      // 中文、中文标点、全角字符按1长度，英文、英文符号、数字按0.5长度计算
      let cnReg = /([\u4e00-\u9fa5]|[\u3000-\u303F]|[\uFF00-\uFF60])/;
      let maxLimitStr = 20; //20个汉字
      let pass = true;
      let substringStr = "";
      let length = 0;
      let strArr = value.split("");
      strArr.map((str) => {
        if (pass) {
          if (cnReg.test(str)) {//中文
            length++;
          } else {//英文
            length += 0.5;
          }
          //大于最大限制的时候
          if (length > maxLimitStr) {
            pass = false;
            Dialog({message: "文字长度已超出最大值，最大值为" + maxLimitStr * 2 + "个字符"})
            //将校验的字符截取返回
            that.nickname = substringStr;
          } else {
            substringStr += str;
          }
        }
      });
      return pass;
    }
  }
}
</script>

<style lang='less' scoped>
  .mine-page{
    margin:0 16px;
  }

  .info-edit-title,.info-edit-item{
    padding:28px 0;
    border-bottom:1px solid rgba(0,0,0,0.1);
    font-size:16px;
    color:#000;
  }

  .info-edit-item{
    padding:12px 0;
    ::v-deep .van-field__control{
      font-size:16px;
    }

    ::v-deep .van-cell{
      padding-left:0;
      padding-right:0;
    }
  }

  .edit-tips{
    color:#CBCBCB;
    padding:12px 0;
  }

  .btn-sure{
    height:48px;
    border-radius:24px;
    font-size:16px;
    margin-top:20px;
  }

</style>
